<!-- 拣货单记录-详情 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <FormItem>
        <span>供货单位：</span>
        <span>{{ topData.handler }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>单据编号：</span>
        <span>{{ topData.order_number }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>拣货仓库：</span>
        <span>{{ topData.warehouse_name }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>拣货日期：</span>
        <span>{{ topData.create_time }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>经手人：</span>
        <span>{{ topData.customer_name }}</span>
      </FormItem>
      <span class="fr pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
    </Form>
    <!-- <Table :productList="listColumns" :productData="listData"></Table> -->
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :TotalQuantity="TotalQuantity" @change-page="changePage1" totalText="条记录" :total="total" :current="pages.page" :pages="pages" :productList="listColumns" :productData="listData" :option_page="option_page"  highlight-row border :row-class-name="rowClassName"></Table>
      <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0">
        <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
      </div> -->
    </div>
    <!-- 返回 -->
    <div class="pageBtm fr">
      <span class="pageBtn finger btnReset" @click="back">返回</span>
    </div>
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'detailsPickingListRecord',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      tabloading: false,
      pages: {
        page: 1,
        rows: 10,
      },
      total: '',
      setupStatus: false,
      titleList: [],
      option_page: '21',
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 55,
          maxWidth: 90,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
          maxWidth: 300,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          minWidth: 70,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          minWidth: 70,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '生产日期',
          key: 'production_date',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '失效日期',
          key: 'valid_period',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 250,
          maxWidth: 350,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 170,
        },
        {
          title: '注册证有效期',
          key: 'licence_valid_period',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '储运条件',
          key: 'storage_condition',
          align: 'center',
          minWidth: 100,
        },
      ],
      // 头部数据
      topData: {},
      TotalQuantity: []
    }
  },
  mounted() {
    this.getGspWarehousePickingDetail()
    this.queryOption()
  },
  methods: {
    changePage1(e) {
      this.pages.page = e
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          // this.getGspWarehousePickingDetail()
        })
    },
    // 反冲成功的行标红
    rowClassName() {
      if (this.$route.query.recoil_status == 1) return 'redRow'
      return ''
    },
    // 获取拣货单详情
    getGspWarehousePickingDetail() {
      this.tabloading = true
      let obj = { 
        warehouse_picking_id: this.$route.query.warehouse_picking_id,
        page: this.pages.page,
        rows: this.pages.rows
        }
      this.$http.get(this.$api.gspWarehousePickingDetail, obj, true).then(res => {
        this.tabloading = false
        this.topData = res.data
        this.total = res.data.total
        this.TotalQuantity.push({ title: '合计金额', total: '¥'+res.data.total_amount })
        this.TotalQuantity.push({ title: '总数量', total: res.data.total_quantity })
        this.listData = res.data.result
        // 处理日期
        this.topData.create_time = this.topData.create_time ? this.$moment(this.topData.create_time * 1000).format('YYYY-MM-DD') : ''
        this.listData.forEach(item => {
          item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
          item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : ''
          item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date) : ''
          item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : ''
          item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period) : ''
          item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
          item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
          if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.business_license_valid_period = '长期'
          } else {
            item.business_license_valid_period = ''
          }
          if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.record_valid_period = '长期'
          } else {
            item.record_valid_period = ''
          }
          item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
          item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
          item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
          item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
          item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
          item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
          item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
          item.unit_price = item.unit_price ? this.$utils.formatMoney(item.unit_price,true) : ''
          item.sale_total_price = item.sale_total_price ? this.$utils.formatMoney(item.sale_total_price,true) : ''
        })
      })
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="less">
.pageBtm {
  margin-top: 20px;
}
</style>
